import { type AxiosResponse } from 'axios';

import {
  type DtoCreatePromptTemplateRequest,
  type DtoExportPromptTemplateResponse,
  type DtoImportPromptTemplateRequest,
  type DtoPreviewPromptTemplateMessageRequest,
  type DtoPreviewPromptTemplateMessageResponse,
  type DtoPromptTemplateListResponse,
  type DtoPromptTemplateResponse,
  type DtoRunPromptTemplateRequest,
  type DtoRunPromptTemplateResponse,
  type DtoUpdatePromptTemplateRequest,
  type SearchPromptTemplatesParams,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class PromptTemplateAPI extends BaseAPIService {
  getTemplates(): Promise<AxiosResponse<DtoPromptTemplateListResponse>> {
    return this.client.get('/prompt-templates');
  }
  getTemplateById(
    id: string
  ): Promise<AxiosResponse<DtoPromptTemplateResponse>> {
    return this.client.get(`/prompt-templates/${id}`);
  }
  createTemplate(
    req: DtoCreatePromptTemplateRequest
  ): Promise<AxiosResponse<DtoPromptTemplateResponse>> {
    return this.client.post('prompt-templates', req);
  }
  updateTemplate(
    id: string,
    req: DtoUpdatePromptTemplateRequest
  ): Promise<AxiosResponse<DtoPromptTemplateResponse>> {
    return this.client.put(`prompt-templates/${id}`, req);
  }
  deleteTemplate(id: string): Promise<AxiosResponse<void>> {
    return this.client.delete(`prompt-templates/${id}`);
  }
  cloneTemplate(id: string): Promise<AxiosResponse<DtoPromptTemplateResponse>> {
    return this.client.post(`prompt-templates/${id}/clone`);
  }
  lockTemplate(id: string): Promise<AxiosResponse<DtoPromptTemplateResponse>> {
    return this.client.post(`prompt-templates/${id}/lock`);
  }
  runTemplate(
    req: DtoRunPromptTemplateRequest
  ): Promise<AxiosResponse<DtoRunPromptTemplateResponse>> {
    return this.client.post('prompt-templates/run', req);
  }
  searchTemplates(
    params: SearchPromptTemplatesParams
  ): Promise<AxiosResponse<DtoPromptTemplateListResponse>> {
    return this.client.get('prompt-templates/search', { params });
  }
  exportTemplates(
    keyPrefix: string
  ): Promise<AxiosResponse<DtoExportPromptTemplateResponse>> {
    return this.client.post(`prompt-templates/export`, {
      mappingKeyPrefix: keyPrefix,
    });
  }
  importTemplates(
    req: DtoImportPromptTemplateRequest
  ): Promise<AxiosResponse<void>> {
    return this.client.post(`prompt-templates/import`, req);
  }
  previewMessage(
    req: DtoPreviewPromptTemplateMessageRequest
  ): Promise<AxiosResponse<DtoPreviewPromptTemplateMessageResponse>> {
    return this.client.post(`prompt-templates/preview-message`, req);
  }
}
