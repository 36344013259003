import { type AxiosResponse } from 'axios';

import { type DtoGetOthersPlayingResponse } from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/media';

import { SessionMode } from '../../types';
import { type JoyCapture } from '../../types/joyCapture';
import { BaseAPIService } from './base.api';

export type GameScore = {
  id: string;
  sessionId: string;
  organizationId: string;
  organizationName: string;
  organizationLogo: Media | null;
  teamId: string;
  teamName: string;
  userIds: string[] | null;
  shortNames: string;
  fullNames?: string;
  gamePackId: string;
  gamePackName: string;
  score: number;
  pairingId: string;
  venueId: string;
  mode: string;
  createdAt: string;
  updatedAt: string;
  joyCaptures: JoyCapture[] | null;
};

export interface GameScoreListResponse {
  gameScores: GameScore[];
}

export type GameScoreSearchParams = {
  // comma separated list of gamepackIds to include in the search.
  gamePackIds: string;
  mustPairing: boolean;
  size: number;
  mode: SessionMode;
  organizationId: string;
  sortByUpdatedAt: boolean;
  // scores less than min score will be filtered out.
  minScore: number;
  joyCaptures: boolean;
};

export class GameScoreAPI extends BaseAPIService {
  searchGameScore(
    gamePackIds: string,
    opts: Partial<Omit<GameScoreSearchParams, 'gamePackIds'>>
  ): Promise<AxiosResponse<GameScoreListResponse>> {
    const params = {
      gamePackIds,
      mode: SessionMode.OnDemand,
      size: 20,
      mustPairing: false,
      sortByUpdatedAt: false,
      joyCaptures: false,
      ...opts,
    };

    return this.client.get<GameScoreListResponse>('/game-scores/search', {
      params,
    });
  }

  getOthersPlaying(
    gamePackId: string
  ): Promise<AxiosResponse<DtoGetOthersPlayingResponse>> {
    return this.client.get('/game-scores/others-playing', {
      params: { gamePackId },
    });
  }
}
