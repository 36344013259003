import { type AxiosResponse } from 'axios';

import {
  type DtoCreateSharedAssetRequest,
  type DtoExternalMediaSearchResponse,
  type DtoSharedAsset,
  type DtoSharedAssetListResponse,
  type DtoSharedAssetObjectLinkListResponse,
  type DtoSharedAssetResponse,
  type DtoUpdateSharedAssetRequest,
  type QuerySharedAssetParams,
  type SearchExternalMediaParams,
} from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/media';

import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';
import { BaseAPIService } from './base.api';
import { Paginator } from './pagination';

type MediaResponse = {
  media: Media;
};

export class MediaAPI extends BaseAPIService {
  getMedia(mediaId: string): Promise<AxiosResponse<MediaResponse>> {
    return this.client.get(`/media/${mediaId}`);
  }
  upload(
    data: Blob,
    opts?: {
      contentType?: string;
      scene?: string;
    }
  ): Promise<AxiosResponse<MediaResponse>> {
    const headers = {};
    uncheckedIndexAccess_UNSAFE(headers)['Content-Type'] =
      opts?.contentType ?? data.type;
    if (opts?.scene) {
      uncheckedIndexAccess_UNSAFE(headers)['x-lp-scene'] = opts.scene;
    }
    return this.client.post('/media/upload', data, {
      headers,
    });
  }

  getSharedAsset(id: string) {
    return this.client.get<DtoSharedAssetResponse>(`/media/shared/${id}`);
  }

  searchSharedAsset(params?: { q: string | null; purposes?: string }) {
    return new Paginator<DtoSharedAssetListResponse, DtoSharedAsset>(
      this.client,
      '/media/shared/search',
      'sharedAssets',
      {
        size: 24,
        config: {
          params: params,
        },
      }
    );
  }

  querySharedAsset(req: QuerySharedAssetParams & { size?: number }) {
    return new Paginator<DtoSharedAssetListResponse, DtoSharedAsset>(
      this.client,
      '/media/shared',
      'sharedAssets',
      {
        size: req.size ?? 24,
        config: {
          params: req,
        },
      }
    );
  }

  createSharedAsset(req: DtoCreateSharedAssetRequest) {
    return this.client.post<DtoSharedAssetResponse>('/media/shared', req);
  }

  updateSharedAsset(id: string, req: DtoUpdateSharedAssetRequest) {
    return this.client.put<DtoSharedAssetResponse>(`/media/shared/${id}`, req);
  }

  deleteSharedAsset(id: string) {
    return this.client.delete<void>(`/media/shared/${id}`);
  }

  getSharedAssetLinks(id: string) {
    return this.client.get<DtoSharedAssetObjectLinkListResponse>(
      `/media/shared/${id}/links`
    );
  }

  retrySharedAssetFanOutJob(id: string) {
    return this.client.post<DtoSharedAssetResponse>(
      `/media/shared/${id}/retry-fan-out-job`
    );
  }

  proxyUrl(url: string) {
    return `${
      this.client.defaults.baseURL
    }/media/proxy?url=${encodeURIComponent(url)}`;
  }

  searchExternalMedia(params: SearchExternalMediaParams) {
    return this.client.get<DtoExternalMediaSearchResponse>(
      '/media/external/search',
      {
        params,
      }
    );
  }
}
