import { type AxiosResponse } from 'axios';

import {
  type DeleteProgressionParams,
  type DtoGetProgressionsCountResponse,
  type DtoMultipleProgressionResponse,
  type DtoSingleProgressionResponse,
  type DtoStackProgressionResponse,
  type DtoTrackProgressionBlockPlayedRequest,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class ProgressionAPI extends BaseAPIService {
  createMyProgression(
    packId: string
  ): Promise<AxiosResponse<DtoSingleProgressionResponse>> {
    return this.client.post(`/my/progressions/${packId}`);
  }

  resetMyProgression(
    packId: string
  ): Promise<AxiosResponse<DtoSingleProgressionResponse>> {
    return this.client.post(`/my/progressions/${packId}/reset`);
  }

  resetMyMinigameProgression(
    packId: string,
    minigameId: string
  ): Promise<AxiosResponse<DtoSingleProgressionResponse>> {
    return this.client.post(`/my/progressions/${packId}/reset/${minigameId}`);
  }

  getMyProgression(
    packId: string
  ): Promise<AxiosResponse<DtoSingleProgressionResponse>> {
    return this.client.get(`/my/progressions/${packId}`);
  }

  getMyStackProgression(
    stackId: string
  ): Promise<AxiosResponse<DtoStackProgressionResponse>> {
    return this.client.get(`/my/stack-progressions/${stackId}`);
  }

  trackMyProgressionBlockPlayed(
    packId: string,
    req: DtoTrackProgressionBlockPlayedRequest
  ): Promise<AxiosResponse<DtoSingleProgressionResponse>> {
    return this.client.post(
      `/my/progressions/${packId}/track-block-played`,
      req
    );
  }

  getPackProgressions(
    packId: string
  ): Promise<AxiosResponse<DtoMultipleProgressionResponse>> {
    return this.client.get(`/game-packs/${packId}/progressions`);
  }

  getPackProgressionsCount(
    packId: string
  ): Promise<AxiosResponse<DtoGetProgressionsCountResponse>> {
    return this.client.get(`/game-packs/${packId}/progressions-count`);
  }

  deleteProgression(
    options: DeleteProgressionParams
  ): Promise<AxiosResponse<void>> {
    return this.client.delete(`/progressions/${options.id}`, {
      params: options,
    });
  }
}
